import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { WebMercatorViewport } from '@deck.gl/core'
import DeckGL from '@deck.gl/react'
import {GeoJsonLayer } from '@deck.gl/layers'
import { ExtendedGrid, Task } from 'isoxml'
import {
    gridsVisibilitySelector,
    partfieldsVisibilitySelector,
    timeLogsExcludeOutliersSelector,
    timeLogsFillMissingValuesSelector,
    timeLogsSelectedValueSelector,
    timeLogsVisibilitySelector
} from '../commonStores/visualSettings'
import { isoxmlFileGridsInfoSelector } from '../commonStores/isoxmlFile'
import ISOXMLGridLayer from '../mapLayers/GridLayer'
import { fitBoundsSelector } from '../commonStores/map'
import { formatValue, getGridValue } from '../utils'
import { OSMBasemap, OSMCopyright } from '../mapLayers/OSMBaseLayer'
import { getISOXMLManager, getPartfieldGeoJSON, getTimeLogGeoJSON, getTimeLogsCache, getTimeLogValuesRange } from '../commonStores/isoxmlFileInfo'
import TimeLogLayer from '../mapLayers/TimeLogLayer'
import PartfieldLayer from '../mapLayers/PartfieldLayer'
import { MapMenu, MapMenuProps } from './MapMenu'
import { Console } from 'console'
import MeasurementPointsLayer from '../mapLayers/MeasurementPointsLayer'
import MeasurementLinesLayer from '../mapLayers/MeasurementsLinesLayer'

interface TooltipState {
    x: number, 
    y: number,
    value: string,
    layerType: 'grid' | 'timelog',
    layerId: string
    timeLogValueKey?: string
}

export function Map() {
    const [tooltip, setTooltip] = useState<TooltipState>(null)

    const [initialViewState, setInitialViewState] = useState<any>({
        longitude: 9.5777866,
        latitude: 45.5277534,
        zoom: 13
    })

    const [measurePoints, setMeasurePoints] = useState<number[][]>([]);
    function OnResetMeasurePoints(){
        setMeasurePoints([]);
        setPickDistance(0);
    }

    const [menuState,setMenuState] = useState<number>(3);
    function OnMenuStatusChanged(status:number){
        console.log("Changing Status to: " + status);
        setMenuState(status);

    }

    const isoxmlManager = getISOXMLManager()
    const timeLogsCache = getTimeLogsCache()

    const fitBounds = useSelector(fitBoundsSelector)
    const gridsInfo = useSelector(isoxmlFileGridsInfoSelector)
    const visibleGrids = useSelector(gridsVisibilitySelector)

    const visibleTimeLogs = useSelector(timeLogsVisibilitySelector)
    const timeLogsSelectedValue = useSelector(timeLogsSelectedValueSelector)
    const timeLogsExcludeOutliers = useSelector(timeLogsExcludeOutliersSelector)
    const timeLogsFillMissingValues = useSelector(timeLogsFillMissingValuesSelector)
    const [pickLatitude,setPickLatitude] = useState<number>(0);
    const [pickLongitude,setPickLongitude] = useState<number>(0);
    const [pickDistance,setPickDistance] = useState<number>(0);

    const visiblePartfields = useSelector(partfieldsVisibilitySelector)

    const partfieldLayers = Object.keys(visiblePartfields)
        .filter(key => visiblePartfields[key])
        .map(partfieldId => {
            const geoJSON = getPartfieldGeoJSON(partfieldId)
            return new PartfieldLayer(partfieldId, geoJSON)
        })

    const gridLayers = Object.keys(visibleGrids)
        .filter(taskId => visibleGrids[taskId])
        .map(taskId => {
            const task = isoxmlManager.getEntityByXmlId<Task>(taskId)

            return new ISOXMLGridLayer(
                taskId,
                task.attributes.Grid[0] as ExtendedGrid,
                task.attributes.TreatmentZone,
                gridsInfo[taskId]
            )
        })

    const timeLogLayers = Object.keys(visibleTimeLogs)
        .filter(key => visibleTimeLogs[key])
        .flatMap(timeLogId => {
            const valueKey = timeLogsSelectedValue[timeLogId]
            if (!valueKey) {
                return []
            }
            const excludeOutliers = timeLogsExcludeOutliers[timeLogId]
            const fillValues = timeLogsFillMissingValues[timeLogId]
            const geoJSON = getTimeLogGeoJSON(timeLogId, fillValues)

            const { minValue, maxValue } = getTimeLogValuesRange(timeLogId, valueKey, excludeOutliers)

            return [new TimeLogLayer(timeLogId, geoJSON, valueKey, minValue, maxValue)]
        })

    const measurementPointsLayer = [measurePoints].map((entry)=>{return new MeasurementPointsLayer(entry)});
    const measurementLinesLayer = [measurePoints].map((entry) => new MeasurementLinesLayer(entry));

    
    const viewStateRef = useRef(null)

    const onViewStateChange = useCallback(e => {
        viewStateRef.current = e.viewState 
        setTooltip(null)
    }, [])


    function onMapClickSelector(pickInfo:any){
        if (!pickInfo.layer) {
            setTooltip(null)
            return
        }

        if (pickInfo.layer instanceof ISOXMLGridLayer) {
            const pixel = pickInfo.bitmap.pixel
            const taskId = pickInfo.layer.id

            const task = isoxmlManager.getEntityByXmlId<Task>(taskId)

            const grid = task.attributes.Grid[0] as ExtendedGrid

            const value = getGridValue(grid, pixel[0], pixel[1])
            if (value) {
                const gridInfo = gridsInfo[taskId]
                const formattedValue = formatValue(value, gridInfo)

                setTooltip({
                    x: pickInfo.x,
                    y: pickInfo.y,
                    value: formattedValue,
                    layerType: 'grid',
                    layerId: taskId
                })
            } else {
                setTooltip(null)
            }
        } else if (pickInfo.layer instanceof GeoJsonLayer) {
            const timeLogId = pickInfo.layer.id
            const valueKey = timeLogsSelectedValue[timeLogId]
            const value = pickInfo.object.properties[valueKey]
            const timeLogInfo = timeLogsCache[timeLogId].valuesInfo.find(info => info.valueKey === valueKey)

            const formattedValue = formatValue(value, timeLogInfo)

            setTooltip({
                x: pickInfo.x,
                y: pickInfo.y,
                value: formattedValue,
                layerType: 'timelog',
                layerId: timeLogId,
                timeLogValueKey: valueKey
            })
        } else {
            setTooltip(null)
        }

    }


    function haversineDistance([lat1, lon1], [lat2, lon2]) {
        const R = 6371000; // Earth's radius in meters
        const toRad = (deg) => (deg * Math.PI) / 180;
        
        const dLat = toRad(lat2 - lat1);
        const dLon = toRad(lon2 - lon1);
        
        const a =
          Math.sin(dLat / 2) ** 2 +
          Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) *
          Math.sin(dLon / 2) ** 2;
        
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return R * c; // Distance in meters
      }

      function calculateTotalDistance(points) {
        if (points.length === 0) return 0;
        let totalDistance = 0;
        
        // Loop through the array, starting at the second element
        for (let i = 1; i < points.length; i++) {
          // Calculate the distance from the previous point to the current one in meters
          totalDistance += haversineDistance(points[i - 1], points[i]);
        }
        
        return totalDistance;
      }

    function onMapDistanceMeasureClick(pickInfo:any){
        // Create a new copy of the array:
        const nextPoints = [...measurePoints];
        // Create the new point:
        const nextPos = [pickInfo.coordinate[0], pickInfo.coordinate[1]];
        // Append the new point:
        nextPoints.push(nextPos);
        // Update the state with the new array:
        setMeasurePoints(nextPoints);

        setPickDistance(calculateTotalDistance(nextPoints));
    }

    function onMapPositionClick(pickInfo:any){
        console.log(pickInfo);
        setPickLatitude(pickInfo.coordinate[1]);
        setPickLongitude(pickInfo.coordinate[0]);
    }

    const onMapClick = useCallback(pickInfo => {
        switch(menuState){
            case 1:
                onMapClickSelector(pickInfo);
                break;
            case 2:
                onMapDistanceMeasureClick(pickInfo);
                break;
            case 3:
                onMapPositionClick(pickInfo);
                break;
        }
            }, [isoxmlManager, gridsInfo, timeLogsSelectedValue, timeLogsCache, menuState, measurePoints])

    useEffect(() => {
        if (fitBounds) {
            const viewport = new WebMercatorViewport(viewStateRef.current)
            const {longitude, latitude, zoom} = viewport.fitBounds(
                [fitBounds.slice(0, 2), fitBounds.slice(2, 4)],
                {padding: 8}
            ) as any
            setInitialViewState({
                longitude,
                latitude,
                zoom: Math.min(20, zoom),
                pitch: 0,
                bearing: 0,
                __triggerUpdate: Math.random() // This property is used to force DeckGL to update the viewState
            })
            setTooltip(null)
        }
    }, [fitBounds])

    let isTooltipVisible = false
    if (tooltip) {
        if (tooltip.layerType === 'grid') {
            isTooltipVisible = !!visibleGrids[tooltip.layerId]
        } else {
            isTooltipVisible = !!visibleTimeLogs[tooltip.layerId] &&
                timeLogsSelectedValue[tooltip.layerId] === tooltip.timeLogValueKey
        }
    }

    return (<>
        <DeckGL
            initialViewState={initialViewState}
            controller={true}
            layers={[OSMBasemap, ...gridLayers, ...partfieldLayers, ...timeLogLayers, ...measurementPointsLayer, ...measurementLinesLayer]}
            onViewStateChange={onViewStateChange}
            onClick={onMapClick}
        >
            <OSMCopyright />
            {isTooltipVisible && (<>
                <Box
                    sx={{
                        backgroundColor: 'blue',
                        position: 'absolute',
                        width: 4,
                        height: 4,
                        borderRadius: 3,
                        transform: 'translate(-50%, -50%)',
                    }}
                    style={{left: tooltip.x, top: tooltip.y}}
                />
                <Box
                    sx={{
                        backgroundColor: 'white',
                        border: '1px solid gray',
                        position: 'absolute',
                        transform: 'translate(-50%, -120%)',
                        padding: 2,
                        borderRadius: 2
                    }}
                    style={{left: tooltip.x, top: tooltip.y}}
                >
                    {tooltip.value}
                </Box>
            </>)}

        </DeckGL>
        <MapMenu  Latitude={pickLatitude} Longitude={pickLongitude} Distance={pickDistance} PointCount={measurePoints.length} OnStatusChange={OnMenuStatusChanged} OnResetMeasurements={OnResetMeasurePoints}/>
        </>)
}